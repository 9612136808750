import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import NewsSingle from "../../pageTemplates/NewsSingle";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicNewsArticles }) => {
  const { uid, data } = prismicNewsArticles;
  const { image, display_date, ...rest } = data;

  const resolved = {
    data: {
      uid,
      date: display_date,
      ...rest,
      image: resolveImageData({ image }),
    },
  };

  return keysToCamel(resolved);
};

const NewsSingleQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <NewsSingle {...data} location={location} />;
};

export default NewsSingleQuery;

export const query = graphql`
  query($uid: String!) {
    prismicNewsArticles(uid: { eq: $uid }) {
      uid
      data {
        title
        description {
          text
          html
        }
        display_date(formatString: "MMM DD, YYYY")
        image {
          url
          dimensions {
            width
            height
          }
          alt
        }
        article_link {
          kind
          url
          target
        }
        publication
        publication_medium
      }
    }
  }
`;
