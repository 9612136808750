import React from "react";
import { SingleHero, SingleHeader, SingleCTA } from "@modules/singles/index";
import { SEO } from "@common";
import { RichText, Section, Container } from "@ui";

/* eslint react/prop-types: 0 */

const NewsSingle = ({ data }) => {
  const {
    uid,
    title,
    date,
    description,
    image,
    articleLink,
    publication,
    publicationMedium,
  } = data;

  return (
    <>
      <SEO title={title} description={description.text} />

      <SingleHero />

      <Section size="sm">
        <Container size="cb" className="relative">
          <SingleHeader
            uid={uid}
            backUrl="/in-the-news/"
            backText="all articles"
            title={title}
            date={date}
          />
        </Container>
      </Section>

      <Section hasPaddingTop={false}>
        <Container size="cb">
          <RichText
            html={description.html}
            className="RichText--content-builder text-lg mb-6 lg:mb-12"
          />

          <SingleCTA
            link={articleLink}
            image={image}
            publication={publication}
            medium={publicationMedium}
          />
        </Container>
      </Section>
    </>
  );
};

export default NewsSingle;
